import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { LoaderFunction, redirect, useLoaderData, useNavigate } from "react-router-dom";
import config from "../../../config";
import isLogin from "../../../utils/isLogin";
import { IPromoCode } from "../../../@types/promoCode";
import NotFound from "../../NotFound";

const StatusList = [
    "ACTIVE", "DEACTIVATE"
] as const;

const Promo = () => {
    const data = useLoaderData() as { user: User, promoCode: IPromoCode } | null;

    const promoCodeData = data?.promoCode;

    const [promoCode, setPromoCode] = useState<IPromoCode>(promoCodeData as IPromoCode)
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            if (!data) return;

            const { user } = data;

            if (isNaN(promoCode.promo) || promoCode.promo < 0 || promoCode.promo > 100) {
                setError("promo is not valid");
                return;
            }

            const body = {
                name: promoCode.name,
                promo: promoCode.promo,
                status: promoCode.status

            };
            const response = await axios.post(
                `${config.api_server_uri}/admin/promo-code/edit/${promoCode._id}`,
                body,
                {
                    headers: {
                        Authorization: user.token
                    }
                });
            if (response.status === 200) {
                setMessage(response.data.message);
                navigate("/admin#promo");
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError("error to join server");
                }
            } else if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("error to join server");
            }
        }
    };

    useEffect(() => {
    }, []);

    if (!promoCode) return <NotFound />

    return (
        <div>
            <form className="min-h-screen w-screen dark:bg-gray-800 dark:text-white text-black" onSubmit={handleSubmit}>
                {message ? (
                    <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                        <div className="w-full sm:w-1/2">
                            <div
                                id="alert-border-3"
                                className="flex p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-900 dark:border-green-800 rounded-xl"
                            >
                                <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <div className="ml-3 text-sm font-medium">{message}</div>
                                <button
                                    type="button"
                                    className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                                    onClick={() => {
                                        setError(null);
                                        setMessage(null);
                                    }}
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
                {error ? (
                    <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                        <div className="w-full sm:w-1/2">
                            <div id="alert-border-3" className="flex p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-red-900 dark:border-red-800 rounded-xl">
                                <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <div className="ml-3 text-sm font-medium">{error}</div>
                                <button
                                    type="button"
                                    className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                                    onClick={() => {
                                        setError(null);
                                        setMessage(null);
                                    }}
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="h-10 fixed top-28 right-8 z-40">
                    <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                        create plan
                    </button>
                </div>

                <div className="absolute top-0 right-0 w-36 h-36 flex items-center justify-center z-30">
                    <div className="flex items-center justify-center ">
                        <div className="m-5">
                            <div
                                className="flex p-2.5 transition-all duration-300 text-white rounded-xl darlk:bg-gray-800 bg-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
                                onClick={() => navigate(-1)}
                            >
                                <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="h-auto w-full flex items-center justify-center dark:text-white pt-16 flex-col text-black">
                    <div className="inline-block align-bottom bg-gray-100 dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all sm:w-1/2 rounded-none sm:rounded-lg w-full">
                        <div className="bg-gray-200 p-5 dark:bg-gray-900 ">
                            <div className="sm:flex sm:items-start ">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium">name</h3>
                                    <input
                                        className="text-2xl font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                        value={promoCode.name}

                                        onChange={(event) => {

                                            setPromoCode((promoCode) => ({
                                                ...promoCode,
                                                name: event.target.value
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 dark:bg-gray-900">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium text-gray-400">statue</h3>
                                    <select
                                        value={promoCode.status}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        onChange={(event) => {
                                            if (StatusList.some((status) => event.target.value === status)) {
                                                setPromoCode((promoCode) => ({
                                                    ...promoCode,
                                                    status: event.target.value as any
                                                }))
                                            }
                                        }}
                                    >
                                        {StatusList.map((status, index) => (
                                            <option key={index} value={status}>
                                                {status}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-200 px-5 pb-5 dark:bg-gray-900 ">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium ">discount</h3>
                                    <div className="flex justify-center text-2xl gap-2">
                                        <input
                                            type="number"
                                            className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                            value={promoCode.promo}
                                            max={100}
                                            min={0}
                                            step={1}
                                            onChange={(event) => {
                                                const value = event.target.valueAsNumber;
                                                if (isNaN(value) || value < 0 || value > 100) {
                                                    return;
                                                }
                                                setPromoCode((promoCode) => ({
                                                    ...promoCode,
                                                    promo: event.target.valueAsNumber
                                                }))
                                            }}
                                        />
                                        %
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </form>
        </div>
    );
};

export default Promo;
export const loader: LoaderFunction = async ({ params }) => {
    try {
        const isLog = await isLogin();
        if (!isLog || !isLog.user || isLog.user.role === "customer") return redirect("/login");
        const promoid = params.promoid;
        const promoCode = isLog.user.promoCodes.find((promo) => promo._id === promoid);

        if (!promoCode) {
            throw new Error();
        }

        return {
            ...isLog,
            promoCode
        };
    } catch (err) {
        return null;
    }
};
