import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IPromoCode } from '../../../@types/promoCode';
const PromoCodeCard = (
    props: {
        promoCodes: IPromoCode[]
    }
) => {
    const navigate = useNavigate();
    const { promoCodes } = props;
    const dateFormateur = Intl.DateTimeFormat("en-US", { dateStyle: "medium", timeStyle: "short" })
    // const currencyFormatter = new Intl.NumberFormat('en-US', { style: "currency", currency: "USD" });

    return (

        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            promo name
                        </th>
                        <th scope="col" className="py-3 px-6">
                            discount
                        </th>
                        <th scope="col" className="py-3 px-6">
                            create at
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {promoCodes.map((promoCode) => <tr
                        key={promoCode._id}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                        onClick={() => {
                            navigate(`/admin/promo/${promoCode._id}`)
                        }}>
                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {promoCode.name}
                        </th>
                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            -{promoCode.promo}%
                        </th>
                        <td className="py-4 px-6">
                            {dateFormateur.format(new Date(promoCode.create_at))}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>

    );
};

export default PromoCodeCard;