import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import Login, { loader as loginLoader } from "./login";
import ForgotPassword from "./login/forgot-password";
import Register, { loader as RegisterLoader } from "./register";

import Dashboard, { loader as DashboardLoader } from "./dashboard";
import PaintDashboard, { loader as PaintDashboardLoader } from "./dashboard/paint";

import Paint, { loader as PaintLoader } from "./paint";
import Home, { loader as HomeLoader } from "./home";
import Gallery, { loader as GalleryLoader } from "./gallery";

import Plans, { loader as PlansLoader } from "./plans";
import PayPaint, { loader as PayPaintLoader } from "./dashboard/paint/pay";
import BuyPlan, { loader as BuyPlanLoader } from "./plans/buy";

import Admin, { loader as AdminLoader } from "./admin";
import AdminPaint, { loader as AdminPaintLoader } from "./admin/paint/";
import AdminCreatePaint, { loader as AdminCreatePaintLoader } from "./admin/paint/create";
import AdminPlan, { loader as AdminPlanLoader } from "./admin/plan/";

import Auth, { loader as AuthLoader } from "./auth";
import AuthConfirm, { loader as AuthConfirmLoader } from "./auth/confirm";
import RestPassword, { loader as RestPasswordLoader } from "./auth/rest-password";

import Compose, { loader as ComposeLoader } from "./compose";

import About, { loader as AboutLoader } from "./about";
import ComposeGallery, { loader as ComposeGalleryLoader } from "./compose-gallery";
import PlanCreate, { loader as PlanCreateLoader } from "./admin/plan/create";
import PromoCreate, { loader as PromoCreateLoader } from "./admin/promo/create";
import PromoEdit, { loader as PromoEditLoader } from "./admin/promo/";
import NotFound from "./NotFound";
import Review from "./review";

const Routes = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/">
                <Route index element={<Home />} loader={HomeLoader} />
                <Route path="login">
                    <Route index element={<Login />} loader={loginLoader} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>
                <Route path="register" element={<Register />} loader={RegisterLoader} />
                <Route path="admin">
                    <Route index element={<Admin />} loader={AdminLoader} />
                    <Route path="paint">
                        <Route path="create" element={<AdminCreatePaint />} loader={AdminCreatePaintLoader} />
                        <Route path=":paintId" element={<AdminPaint />} loader={AdminPaintLoader} />
                    </Route>
                    <Route path="plan">
                        <Route index element={<PlanCreate />} loader={PlanCreateLoader} />
                        <Route path=":planId" element={<AdminPlan />} loader={AdminPlanLoader} />
                    </Route>
                    <Route path="promo">
                        <Route index element={<PromoCreate />} loader={PromoCreateLoader} />
                        <Route path=":promoid" element={<PromoEdit />} loader={PromoEditLoader} />
                    </Route>
                </Route>
                <Route path="dashboard">
                    <Route index element={<Dashboard />} loader={DashboardLoader}></Route>
                    <Route path="paint">
                        <Route path=":id" element={<PaintDashboard />} loader={PaintDashboardLoader} />

                        <Route path="pay">
                            <Route path=":id" element={<PayPaint />} loader={PayPaintLoader} />
                        </Route>
                    </Route>
                </Route>
                <Route path="gallery">
                    <Route index element={<Gallery />} />
                    <Route path=":paintId">
                        <Route element={<Gallery />} loader={GalleryLoader} index />
                        <Route path="compose" element={<ComposeGallery />} loader={ComposeGalleryLoader} />
                    </Route>
                </Route>
                <Route path="plans">
                    <Route index element={<Plans />} loader={PlansLoader} />
                    <Route path=":id" element={<BuyPlan />} loader={BuyPlanLoader} />
                </Route>
                <Route path="auth">
                    <Route index element={<Auth />} loader={AuthLoader} />
                    <Route path="confirm">
                        <Route path=":confirmToken" element={<AuthConfirm />} loader={AuthConfirmLoader} />
                    </Route>
                    <Route path="rest-password">
                        <Route path=":resetToken" element={<RestPassword />} loader={RestPasswordLoader} />
                    </Route>
                </Route>
                <Route path="review/:paintId" element={<Review />} />
                <Route path="about" element={<About />} loader={AboutLoader} />
                <Route path="paint">
                    <Route index element={<NotFound />} />
                    <Route path=":id">
                        <Route index element={<Paint />} loader={PaintLoader} />
                        <Route path="compose" element={<Compose />} loader={ComposeLoader} />
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Route>
    )
);

export default Routes;
