/* eslint-disable react-hooks/exhaustive-deps */
import { DragEvent, useState, ChangeEvent, useEffect } from "react";
import { TSVG } from "../../../@types/svg";
import { Admin } from "../../../@types/user";
import config from "../../../config";
import axios from "axios";
import { Errors } from "../../../utils/errors";
import { toast } from "sonner";

const Upload = (props: { user: Admin }) => {
    const { user } = props;
    const [files, setFiles] = useState<{ name: string; blob: Blob; url: string }[]>([]);
    const [isDraging, setIsDraging] = useState<boolean>(false);
    const [svgs, setSvgs] = useState<TSVG[]>([]);
    const [editSvgs, setEditSvg] = useState<TSVG[]>([]);
    const [fileloading, setFileloading] = useState<boolean>(false);
    const [editSvgloading, setEditSvgloading] = useState<boolean>(false);

    const loadFiles = async (files: FileList) => {
        for (let file of files) {
            if (file.type !== "image/svg+xml") return toast.error(`"${file.name}" is not a svg file`);
            const arrayBuffer = await file.arrayBuffer();
            const blob = new Blob([arrayBuffer], { type: file.type });

            const image = new Image();
            const url = URL.createObjectURL(blob);
            image.addEventListener("error", () => {
                toast.error(`error to load ${file.name},svg not compatible`);
            });
            image.addEventListener("load", () => {
                setFiles((files) => {
                    return [
                        ...files,
                        {
                            name: file.name,
                            blob,
                            url,
                        },
                    ];
                });
            });

            image.src = url;
        }
    };
    const onDrop = async (event: DragEvent) => {
        event.preventDefault();
        loadFiles(event.dataTransfer.files);
        setIsDraging(false);
    };
    const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return toast.message(`0 files in input`);
        loadFiles(event.target.files);
    };
    const ondragStart = (event: DragEvent) => {
        event.preventDefault();
        setIsDraging(true);
    };
    const onDragLeave = () => {
        setIsDraging(false);
    };
    const loadSvg = async () => {
        try {
            const url = `${config.api_server_uri}/svg`;
            const responce = await axios.get(url);
            setSvgs(responce.data.svgs);
        } catch (error) {
            Errors.handler(error);
        }
    };
    const submitEditSvg = async () => {
        try {
            if (editSvgloading) return;
            setEditSvgloading(true);
            for (let i = 0; i < editSvgs.length; i++) {
                const editSvg = editSvgs[i];
                const url = `${config.api_server_uri}/admin/svg/${editSvg._id}`;
                toast.success(`on going treatment in file ${editSvg.name}`);
                await axios.put(
                    url,
                    { name: editSvg.name },
                    {
                        headers: {
                            Authorization: user.token,
                        },
                    }
                );
                toast.success(`finished treatment in file ${editSvg.name}`);
            }
            setEditSvgloading(false);
            setEditSvg([]);
        } catch (error) {
            Errors.handler(error);
        }
    };
    const submitFile = async () => {
        try {
            if (fileloading) return;
            setFileloading(true);

            // toast.error(`the post fonctions is not ready`);
            toast.success(`files is on treatment`);
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                await new Promise((resolve, reject) => {
                    try {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                            console.log("load index", i);

                            const url = `${config.api_server_uri}/admin/svg/create`;
                            await axios.post(
                                url,
                                {
                                    name: file.name,
                                    content: reader.result,
                                },
                                {
                                    headers: {
                                        Authorization: user.token,
                                    },
                                }
                            );
                            resolve(null);
                        });
                        reader.readAsBinaryString(file.blob);
                    } catch (error) {
                        reject(error);
                    }
                });
            }
            toast.success(`all files ${files.length} is post`);
            setFileloading(false);
            setFiles([]);
            loadSvg();
        } catch (error) {
            Errors.handler(error);
        }
    };
    const deleteSvg = async (svgId: string) => {
        try {
            const url = `${config.api_server_uri}/admin/svg/${svgId}`;
            const responce = await axios.delete(url, {
                headers: {
                    Authorization: user.token,
                },
            });
            toast.success(responce.data.message);
        } catch (error) {
            Errors.handler(error);
        }
    };
    useEffect(() => {
        loadSvg();
    }, []);
    return (
        <div className="w-full min-h-[50vh]  flex">
            <div className="w-full min-h-[80vh] select-none px-5 py-5">
                <div className="flex items-center justify-center w-full">
                    <label
                        htmlFor="dropzone-file"
                        onDrop={onDrop}
                        onDragOver={ondragStart}
                        onDragLeave={onDragLeave}
                        className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800  hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${
                            isDraging ? "dark:bg-gray-600 bg-gray-50 " : "bg-gray-50 dark:bg-gray-700"
                        }`}
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, xml</p>
                        </div>
                        <input id="dropzone-file" type="file" className="hidden" multiple onChange={onFileInputChange} />
                    </label>
                </div>
                <div className={`w-full h-8 m-5 flex justify-end items-center pr-5`}>
                    <button
                        type="button"
                        disabled={files.length === 0}
                        className={`text-white bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 dark:bg-blue-600 focus:outline-none dark:focus:ring-blue-800 ${
                            files.length === 0 ? "opacity-60" : "opacity-100 dark:hover:bg-blue-700 hover:bg-blue-800"
                        }`}
                        onClick={submitFile}
                    >
                        submit files
                    </button>
                </div>
                <div
                    className={`flex flex-col items-center justify-start w-full min-h-[5rem] h-auto border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:border-gray-600  bg-gray-50 dark:bg-gray-700`}
                >
                    {files.length === 0 ? (
                        <div className={`w-full h-[15rem]  flex justify-center items-center text-white transition-all ${isDraging ? "text-3xl" : "text-2xl"}`}>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">not image</span>
                            </p>
                        </div>
                    ) : null}
                    {files.map((file, index) => (
                        <div key={index} className="h-52 w-full flex justify-between items-center gap-5  px-5">
                            <img width={150} height={150} src={file.url} alt="" draggable="false" />
                            <div className="w-full h-full flex justify-center items-center flex-col">
                                <div className="w-full text-white py-5">File name</div>
                                <input
                                    type="text"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 h-10 w-full"
                                    value={file.name}
                                    multiple
                                    onChange={(event) => {
                                        setFiles((files) => {
                                            const newFiles = [...files];
                                            newFiles[index].name = event.target.value;
                                            return newFiles;
                                        });
                                    }}
                                />
                            </div>
                            <div className="h-full flex justify-center items-center w-52 py-5">
                                <button
                                    className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md"
                                    type="button"
                                    onClick={() => {
                                        setFiles((files) => {
                                            const newFiles = [...files];
                                            newFiles.splice(index, 1);
                                            return newFiles;
                                        });
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full min-h-[80vh] select-none px-5 py-5">
                <div
                    className={` flex flex-col items-center justify-start w-full min-h-[4rem] h-auto border-2 border-gray-300 border-solid rounded-lg cursor-pointer dark:border-gray-600  bg-gray-50 dark:bg-gray-700 select-none`}
                >
                    <div className="w-full flex justify-center items-center h-20 text-2xl text-white font-bold border-b-2 border-gray-600 border-solid">All images in Database</div>
                    <div className={`w-full flex justify-start items-center pt-5 px-5 ${editSvgs.length !== 0 ? "sticky" : null}  top-0`}>
                        <button
                            type="button"
                            disabled={editSvgs.length === 0}
                            className={`text-white bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 dark:bg-blue-600 focus:outline-none dark:focus:ring-blue-800 ${
                                editSvgs.length === 0 ? "opacity-60" : "opacity-100 dark:hover:bg-blue-700 hover:bg-blue-800"
                            }`}
                            onClick={submitEditSvg}
                        >
                            submit edit svg
                        </button>
                    </div>
                    {svgs.length === 0 ? (
                        <div className={`w-full h-[15rem]  flex justify-center items-center text-white transition-all ${isDraging ? "text-3xl" : "text-2xl"}`}>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">not svg in database</span>
                            </p>
                        </div>
                    ) : null}
                    {svgs.map((svg, index) => (
                        <div key={index} className="h-52 w-full flex justify-between items-center gap-5  px-5">
                            <img width={150} height={150} src={`${config.api_server_uri}/svg/${svg._id}`} alt="" draggable="false" />
                            <div className="w-full h-full flex justify-center items-center flex-col">
                                <div className="w-full text-white py-5">File name</div>
                                <input
                                    type="text"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 h-10 w-full"
                                    value={svg.name}
                                    multiple
                                    onChange={(event) => {
                                        setSvgs((svgs) => {
                                            const newSvgs = [...svgs];
                                            newSvgs[index].name = event.target.value;
                                            setEditSvg((editSvg) => {
                                                const currentEditSvgIndex = editSvg.findIndex((svg) => svg._id === newSvgs[index]._id);
                                                if (currentEditSvgIndex !== -1) {
                                                    editSvg[currentEditSvgIndex].name = event.target.value;
                                                } else {
                                                    editSvg.push(newSvgs[index]);
                                                }
                                                return [...editSvg];
                                            });
                                            return newSvgs;
                                        });
                                    }}
                                />
                            </div>
                            <div className="h-full flex justify-center items-center w-52 py-5">
                                <button
                                    className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md"
                                    type="button"
                                    onClick={async () => {
                                        await deleteSvg(svg._id);
                                        setSvgs((svgs) => {
                                            svgs.splice(index, 1);
                                            return [...svgs];
                                        });
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Upload;
