import { PaymentType, TBASEPLAN } from "../../../@types/plan";

export const plan: TBASEPLAN = {
    day_available: 30,
    description: "",
    max_images_in_each_gallery: 10,
    name: "plan base",
    options: ["option 1"],
    type: "payment",
    price: 100,
    questions: null,
    selecter_options: null,
    config: {
        base_uri: new URL(window.location.href),
        socket_server_uri: "ws://141.94.223.85:5151/",
        api_server_uri: "http://141.94.223.85:5151",
        font: "",
        colors_palette: ["#fd990b", "#61d9de", "#ac43e3", "#ea2a96", "#7cde3b", "#eb212e", "#fff02a", "#2582f6", "#fff"],
        default_colors_select: 0,
        undo: {
            icon: "./img/icons/iconsketch-undo.svg",
            size: 60,
        },
        review: {
            use: false,
            notification: {
                email: "tharsa",
                use: false,
            },
        },
        get: {
            name: {
                use: false,
                max: 255,
                min: 0,
            },
        },
        tools: [
            {
                action: "clear",
                html_interface: {
                    title: "Are you sure ?",
                    html_templates: [
                        {
                            action: "clear",
                            show: true,
                            text: "yes",
                        },
                        {
                            action: "close",
                            show: true,
                            text: "no",
                        },
                    ],
                    show: true,
                },
                icon: "./img/icons/iconsketch-clear.svg",
                show: true,
                text: "clear",
            },
            {
                action: "marker",
                active: false,
                cursor_style: "cursor-brush02",
                icon: "./img/icons/iconsketch-brush02.svg",
                show: true,
                size: 3,
                text: "brush {{br}} 0.2",
                iconPath: "./img/icons/cursor-pencil-02-2x.png",
            },
            {
                action: "marker",
                active: true,
                cursor_style: "cursor-brush03",
                icon: "./img/icons/iconsketch-brush03.svg",
                show: true,
                size: 8,
                text: "brush {{br}} 0.3",
                iconPath: "./img/icons/cursor-pencil-03-2x.png",
            },
            {
                action: "marker",
                active: false,
                cursor_style: "cursor-brush04",
                icon: "./img/icons/iconsketch-brush04.svg",
                show: true,
                size: 12,
                text: "brush {{br}} 0.3",
                iconPath: "./img/icons/cursor-pencil-04-2x.png",
            },
            {
                action: "marker",
                active: false,
                cursor_style: "cursor-brush05",
                icon: "./img/icons/iconsketch-brush05.svg",
                show: true,
                size: 18,
                text: "brush {{br}} 0.5",
                iconPath: "./img/icons/cursor-pencil-05-2x.png",
            },
            {
                action: "eraser",
                active: false,
                cursor_style: "cursor-eraser",
                icon: "./img/icons/iconsketch-eraser.svg",
                show: true,
                size: 30,
                text: "eraser",
                iconPath: "./img/icons/cursor-eraser-2x.png",
            },
            {
                action: "bucket",
                active: false,
                cursor_style: "cursor-bucket",
                icon: "./img/icons/iconsketch-bucket.svg",
                show: true,
                text: "bucket",
                iconPath: "./img/icons/cursor-bucket-2x.png",
            },
            {
                action: "addToGallery",
                icon: "./img/icons/iconsketch-gallery.svg",
                text: "add to gallery",
                html_interface: {
                    show: true,
                    title: "Add to the Pop Art gallery ?",
                    html_templates: [
                        {
                            action: "add",
                            show: true,
                            text: "add to gallery {{galleryName}}",
                            postUri: "./gallery/index.php",
                            galleryName: "default",
                        },
                        {
                            action: "fill-gallery-name",
                            postUri: "./gallery/index.php",
                            show: true,
                            text: "fill gallery name",
                        },
                        {
                            action: "create-gallery",
                            postUri: "./gallery/index.php",
                            show: true,
                            text: "create a new gallery",
                        },
                    ],
                },
                show: true,
            },
            {
                action: "link",
                _blank: true,
                href: "https://exemple.com",
                icon: "./img/icons/iconsketch-info.svg",
                show: true,
                text: "info",
            },
        ],
        default_tools_select: 2,
        canvas: {
            scale_raio: 1.2,
            default_size: {
                height: 500,
                width: 500,
            },
            color: "#FFF",
            minsize: 300,
            render: {
                size: {
                    width: 1000,
                    height: 1000,
                },
            },
        },
        eraser: {
            size: 30,
            color: "#FFFFFF",
        },
        use_image: true,
        use_multi_image: true,
        image_paths: [
            // "./img/svg/lettre/a.svg",
            // "./img/svg/lettre/b.svg",
            // "./img/svg/lettre/c.svg",
            // "./img/svg/lettre/d.svg",
            // "./img/svg/lettre/e.svg",
            // "./img/svg/lettre/f.svg",
            // "./img/svg/lettre/g.svg",
            // "./img/svg/lettre/h.svg",
            // "./img/svg/lettre/i.svg",
            // "./img/svg/lettre/j.svg",
            // "./img/svg/lettre/k.svg",
            // "./img/svg/lettre/l.svg",
            // "./img/svg/lettre/m.svg",
            // "./img/svg/lettre/n.svg",
            // "./img/svg/lettre/o.svg",
            // "./img/svg/lettre/p.svg",
            // "./img/svg/lettre/q.svg",
            // "./img/svg/lettre/r.svg",
            // "./img/svg/lettre/s.svg",
            // "./img/svg/lettre/t.svg",
            // "./img/svg/lettre/u.svg",
            // "./img/svg/lettre/v.svg",
            // "./img/svg/lettre/w.svg",
            // "./img/svg/lettre/x.svg",
            // "./img/svg/lettre/y.svg",
            // "./img/svg/lettre/z.svg",
        ],
        default_image_select: 0,
        svg_filter_colors: ["#FFFFFF", "#fff"],
        key_input: {
            undo: 26,
            del: 26,
        },
        intro: {
            animation_step_top: 8,
            animation_step_opacity: 0.005,
            text: "start",
            state: true,
        },
        log: false,
        credit: {
            image: "./img/pop_art_logo.jpg",
            opacity: 0.5,
            position: {
                x: 85,
                y: 85,
            },
            size: {
                height: 20,
                width: 20,
            },
            state: true,
        },
        gallery_default_post_uri: "./gallery/index.php",
        default_gallery_name: "foo",
        message_time_out: 3000,
        title: {
            home: "pop art",
            gallery: "gallery {{name}}",
        },
        interatif: {
            default_row: 4,
            row: true,
            state: true,
            default_size: {
                height: 500,
                width: 500,
            },
            render: {
                size: {
                    height: 500,
                    width: 500,
                },
            },
            gallery: {
                use: false,
                image_line: {
                    count: 3,
                    state: false,
                },
                show: {
                    date: true,
                    name: true,
                },
                text: "go to see {{name}} compose",
            },
        },
        live_draw: {
            max_users: 20,
            state: true,
        },
        show_home: true,
        questions: null,
        gallery: {
            image_line: {
                count: 3,
                state: true,
            },
            show: {
                date: false,
                name: false,
            },
            live_update: true,
            text: "go to see {{paint}} - {{label}}",
        },
    },
};
