import { useState, useEffect } from "react";
import { LoaderFunction, NavLink, redirect, useLoaderData } from "react-router-dom";
import isLogin from "../../utils/isLogin";
import NavBar from "../../components/navbar";
import PaintCard from "./paint-card";
// import PlanCard from "./plan-card";
import UploadComponents from "./components/upload";
import { useNavigate } from "react-router-dom";
import PlanCard from "./plan-card";
import PromoCodeCard from "./promo-card";
type Pages = "paint" | "plan" | "upload" | "promo";

const Admin = () => {
    const { user } = useLoaderData() as { user: User; token: string };
    const hashPage = (window.location.hash.replace("#", "") || "paint") as any;

    const [page, setPages] = useState<Pages>(hashPage);
    const navigate = useNavigate();

    useEffect(() => {
        if (hashPage === "paint" || hashPage === "plan" || hashPage === "upload" || hashPage === "promo") setPages(hashPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        navigate(`#${page}`);
    }, [page, navigate]);
    return (
        <div className="h-full w-screen dark:bg-gray-800 bg-gray-300 overflow-x-hidden">
            <NavBar user={user} />
            <div className="p-7 dark:bg-gray-800 bg-gray-300 ">
                {user && user.role === "admin" ? (
                    <>
                        <div className="w-full h-14 flex justify-end items-center gap-2">
                            <NavLink className="w-28 h-12 bg-green-600 text-white rounded flex justify-center items-center" draggable="false" to="/admin/paint/create">
                                new mural
                            </NavLink>
                            <NavLink className="w-28 h-12 bg-blue-600 text-white rounded flex justify-center items-center" draggable="false" to="/admin/plan">
                                new plans
                            </NavLink>
                            <NavLink className="w-28 h-12 bg-blue-600 text-white rounded flex justify-center items-center" draggable="false" to="/admin/promo">
                                new promo
                            </NavLink>
                        </div>
                        <div className="w-full flex justify-start items-center flex-row gap-4 dark:text-white">
                            <div onClick={() => setPages("plan")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                                Plans
                                {page === "plan" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                            </div>
                            <div onClick={() => setPages("paint")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                                Paint
                                {page === "paint" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                            </div>
                            <div onClick={() => setPages("upload")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                                Upload
                                {page === "upload" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                            </div>
                            <div onClick={() => setPages("promo")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                                Promo
                                {page === "promo" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                            </div>
                        </div>
                        {page === "paint" && (
                            <div>
                                {user.paints.length === 0 ? (
                                    <div className="w-full h-auto dark:text-white text-black flex justify-center items-center p-16 ">no application</div>
                                ) : (
                                    <div className="mt-5">{<PaintCard paints={user.paints} />}</div>
                                )}
                            </div>
                        )}
                        {page === "plan" && (<div>
                            {user.plans.length === 0 ? (
                                <div className="w-full h-auto text-white flex justify-center items-center p-16">no plan</div>
                            ) : (
                                <div className="mt-5">{<PlanCard plans={user.plans} />}</div>
                            )}
                        </div>
                        )}
                        {page === "promo" && (<div>
                            {user.promoCodes.length === 0 ? (
                                <div className="w-full h-auto text-white flex justify-center items-center p-16">no promo code</div>
                            ) : (
                                <div className="mt-5">{<PromoCodeCard promoCodes={user.promoCodes} />}</div>
                            )}
                        </div>
                        )}
                        {page === "upload" && <UploadComponents user={user} />}

                    </>
                ) : null}
            </div>
        </div>
    );
};

export default Admin;
export const loader: LoaderFunction = async (data) => {
    const isLog = await isLogin();
    if (isLog && isLog.user.role === "admin") return isLog;
    else return redirect("/login");
};
