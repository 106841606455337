import axios from "axios"
import config from "../../config/";

const isLogin = async (): Promise<{ user: User, } | null> => {
    const token = localStorage.getItem('token');
    try {
        if (!token) throw new Error()
        const result = await axios.post(`${config.api_server_uri}/user-info`, {}, { headers: { authorization: token } });
        if (result.status !== 201) throw new Error()
        return {
            user: {
                ...result.data,
                token,
            }
        }
    } catch (err) {
        localStorage.removeItem('token');
        return null

    }
};
export default isLogin;