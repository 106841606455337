import axios from 'axios';
import Plan from '../../components/plan';
import config from '../../config';
import TPlan from '../../@types/plan';
import { LoaderFunction, redirect, useLoaderData } from 'react-router-dom';

const Plans = () => {
    const plans = useLoaderData() as TPlan[] | null;

    return (
        <div className='w-full h-full bg-white dark:bg-gray-900 overflow-x-hidden'>

            <div className='bg-white dark:bg-gray-900 h-auto ' >
                <h2 className='text-center text-6xl py-12 text-black dark:text-white'>Plans</h2>
                <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 grid grid-cols-1 sm:grid-cols-2 '>
                    {
                        plans && plans.map((plan) => <Plan key={plan._id} {...plan} />)
                    }
                </div>
            </div>
        </div>

    );
};

export default Plans;

export const loader: LoaderFunction = async () => {
    try {
        const response = await axios.post(`${config.api_server_uri}/plan`);
        if (response.status === 200) {
            const plans = response.data as TPlan[];
            if (plans.length === 1) {
                return redirect(`/plans/${plans[0]._id}`)
            }
            return plans;
        } else {
            return null;
        }
    } catch (err) {
        return null
    }
};